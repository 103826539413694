import React from "react"
import styled, { css } from "styled-components"
import { Button } from "../core/Button"
import { Text } from "../core/commonExports"
import { Input } from "../LeadFormSteps/common"
import Modal from "../Modal"
import { useLeadFormData } from "../../context/LeadFormContext"
import { Loader } from "../core/Loader"
import OTPInput from "./OTPInput"
import { getAuthToken, getLoginOTP, startRfqGenTask, stopTask } from "../../api/selfServeAPIs"
import { mobileNumWithCountryCode } from "../LeadFormSteps/sharedUtils"
import useIsInitialMount from "../../hooks/useIsInitialMount"

const screens = {
  mobileNum: "mobile-num",
  otpInput: "otp-input"
}

const defaultOtpValues = ['', '', '', '', '', '']

const otpInputStyles = {
	width: '100%',
	height: '48px',
	textAlign: 'center',
	borderRadius: '0',
  borderLeft: 'none !important',
	background: '#FBFBFB',
  padding: "0"
}

const MobileNumberScreen = ({mobileNum, onMobileNumChange, disableSubmit, onSubmit}) => {
  return (
    <>
      <Text fontSize="18px" mfontSize="18px" lineHeight="21.78px" mlineHeight="21.78px" fontWeight="bold" color="#8668AD" style={{marginBottom: "30px"}}>
        Enter the correct number 
      </Text>
      <div style={{position: "relative", width: "min(320px, 100%)"}}>
        <PhoneNoPrefix>+91</PhoneNoPrefix>
        <Input 
          placeholder=""
          onInput={onMobileNumChange}
          value={mobileNum}
          type="number"
          required
          autocomplete="tel"
          maxLength="10"
          style={{paddingLeft: "4rem"}}
        />
      </div>
      <StyledButton disabled={disableSubmit} label="Save and send code" uppercaseLabel={false} style={{marginTop: "1rem"}} onClick={onSubmit} />
    </>
  )
}

const prefillMobileNum = (mobileNum) => {
  if (mobileNum) {
    return mobileNum?.replace('+91', '')
  }
  return mobileNum || ''
}

const OTPModal = ({handleClose, autoRfqTimerExpired, onAutoRfqTimerExpired}) => {
  const isInitialMount = useIsInitialMount()
  const [state, dispatch] = useLeadFormData()
  
  const [screen, setScreen] = React.useState(screens.otpInput)
  const [mobileNum, setMobileNum] = React.useState(() => prefillMobileNum(state?.mobile))
  
  const [otpValues, setOtpValues] = React.useState(defaultOtpValues)
  const [otpSent, setOtpSent] = React.useState(false)
	const [intervalHandler, setIntervalHandler] = React.useState(null)
	const [timeLeft, setTimeLeft] = React.useState(120)
  const [rfqGenJobId, setRfqGenJobId] = React.useState('')

  // API call states
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState('')

  const handleOtpValueChangeAtIndex = (index, value) => {
		let newValues = [...otpValues]
		newValues[index] = value
		setOtpValues(newValues)
	}

	const handleAllOtpValuesChange = values => {
		setOtpValues(values)
	}

  const timer = new Date(timeLeft * 1000).toISOString().slice(14, 19)
	let resendCodeTimer = `Resend code in ${timer}`
	if (timeLeft === 0) {
		resendCodeTimer = 'Resend code'
	}

	const otp = otpValues?.join('')?.trim()

  const validMobileNum = mobileNum.length === 10
  const validOtp = otp.length === 6

  const handleMobileNumInput = e => {
    const val = e.target.value;
    if (val.length <= 10) {
      setMobileNum(e.target.value);
    }
    e.target.value = val.slice(0, 10);
    e.target.value.replace(/[^0-9]g/, '')
  }

  const handleApiError = (e, errorText = "Some error occured") => {
    console.log(e)
    setIsSubmitting(false)
    setError(errorText)
    setTimeout(() => setError(""), 5000)
  }

  const sendOtp = async () => {
    try {
      setIsSubmitting(true)
      const result = await getLoginOTP(
        mobileNumWithCountryCode(mobileNum)
      )
      if (result.error) {
        setError(result.error)
      } else {
        if (error) setError('')
        const handler = setInterval(() => {
          setTimeLeft(value => {
            return value ? value - 1 : 0
          })
        }, 1000)
        setIntervalHandler(handler)
        setScreen(screens.otpInput)
        setIsSubmitting(false)
      } 
      setOtpSent(true)
    } catch(e) {
      handleApiError(e)
    }
  }

  const resendOtp = async () => {
		// resend otp only after time (2 min) is finished
		if (timeLeft === 0) {
			setError('')
			await new Promise((resolve, reject) => {
				setOtpValues(defaultOtpValues)
				setTimeLeft(120) // reset time to resend OTP
				clearInterval(intervalHandler)
				setIntervalHandler(null)
				resolve()
			})
			sendOtp()
		}
	}

  const verifyOtp = async () => {
    try {
      // OTP verification API call goes here...
      setIsSubmitting(true)
      const result = await getAuthToken(
        mobileNumWithCountryCode(mobileNum), 
        otp
      )
      if (result.error) {
        setError(result.error)
      } else {
        sessionStorage.setItem('token', result.token)
			  sessionStorage.setItem('user', JSON.stringify(result.data))
        setIsSubmitting(false)
        // end the auto rfq generation task here if 5 mins timer hasn't expired
        if (!autoRfqTimerExpired) {
          await stopTask(rfqGenJobId)
        }
        handleClose()
      }
    } catch(e) {
      handleApiError(e, "Invalid OTP")
    }
  }

  const changeNumber = () => {
    setScreen(screens.mobileNum)
  }

  React.useEffect(() => {
    let timer = null
    const startAutoRfqTimer = async () => {
      await sendOtp()

      // start the auto rfq generation job on the backend
      const phone = mobileNumWithCountryCode(mobileNum)
      const res = await startRfqGenTask({Phone: phone})
      if (res.jobId) {
        setRfqGenJobId(res.jobId)
      }

      // start a timer for 5 mins to auto-gen rfq on the backend
      timer = setTimeout(() => {
        onAutoRfqTimerExpired()
      }, 300000) 
    }

    if (isInitialMount && mobileNum) {
      startAutoRfqTimer()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [mobileNum])

  let content = <MobileNumberScreen mobileNum={mobileNum} onMobileNumChange={handleMobileNumInput} onSubmit={sendOtp} disableSubmit={!validMobileNum} />

  if (screen === screens.otpInput) {
    content = (
      <>
        <Text fontSize="18px" mfontSize="18px" lineHeight="21.78px" mlineHeight="21.78px" fontWeight="bold" color="#8668AD" style={{marginBottom: "30px", textAlign: "center"}}>
          Verify your mobile number by entering the code sent to you
        </Text>
        <div style={{position: "relative", width: "min(320px, 100%)"}}>
          <PhoneNoPrefix>+91</PhoneNoPrefix>
          <Input 
            value={mobileNum}
            disabled
            style={{paddingLeft: "4rem"}}
          />
          {/* <ChangeNumberBtn label="Change number" uppercaseLabel={false} onClick={changeNumber} /> */}
        </div>
        <OtpContainer className="d-flex">
          <OTPInput
            onValueChangeAtIndex={handleOtpValueChangeAtIndex}
            onAllValuesChange={handleAllOtpValuesChange} // in case of paste event
            values={otpValues}
            inputStyle={otpInputStyles}
            containerStyle={{ paddingLeft: '0.25rem' }}
          />
          <OtpResendButton
            uppercaseLabel={false}
            label={otpSent ? resendCodeTimer : 'Send OTP'}
            onClick={otpSent ? resendOtp : sendOtp}
            disabled={!otpSent ? !validMobileNum : !validMobileNum || timeLeft > 0}
          />
        </OtpContainer>
        <StyledButton disabled={!validOtp || !validMobileNum} label="Verify & view quotes" uppercaseLabel={false} style={{marginTop: "1rem"}} onClick={verifyOtp} />
      </>
    )
  }

  if (isSubmitting) {
    content = (<>
      {/* <Text
        fontSize="24px"
        mfontSize="18px"
        color={"#4D4D4D"}
        fontWeight="bold"
        style={{ marginBottom: '50px' }}
      >
        Submitting...
      </Text> */}
      <Loader style={{margin: "2.5rem"}} />
    </>)
  }

  return (
    <Modal handleClose={handleClose} showClose={false}>
      {content}
      {error && <Text color="red">{error}</Text>}
    </Modal>
  )
}

const StyledButton = styled(Button)`
  background: #F78670;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  width: min(320px, 100%);
  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`

const phoneNumCss = css`
  --color: #C8D6DB;
  position: absolute; 
  left: 0; 
  top: 0; 
  height: fit-content;
  padding: 0.72rem 1rem; 
  border: 1px solid var(--color);
  border-radius: 5px 0 0 5px;
  background: #FBFBFB;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 19.36px;
`

const PhoneNoPrefix = styled.span`
 ${phoneNumCss};
`

const ChangeNumberBtn = styled(Button)`
  ${phoneNumCss};
  right: 0;
  left: auto;
  padding: 0.88rem;
  min-width: 132px;
  color: #F78670;
  border-radius: 0 5px 5px 0;
  box-shadow: none;
  line-height: 14.52px;
`

const OtpContainer = styled.div`
  margin-top: 1rem;
	background: #fbfbfb;
	border: 0.5px solid #c8d6db;
	border-radius: 8px;
	width: min(320px, 100%);
`

const OtpResendButton = styled(Button)`
	width: 400px;
	height: 48px;
	border-radius: 0 8px 8px 0;
  box-shadow: none;
	font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  color: #333333; 
  background: #FBFBFB;
  min-width: 132px;
  &:disabled {
    opacity: 0.5 !important;
  }
`

export default OTPModal